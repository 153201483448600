import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Header from "src/components/headers/header";
import Ariane from "src/components/headers/ariane";
import Footer from "src/components/footers/footer";
import Sidebar from "src/components/sidebars/sidebarStandard";
import AvisGoogle from "src/components/sidebars/avisGoogle";

import Engagement from "../components/shortcodes/Engagement";
import Cta from "../components/shortcodes/Cta";
import Presse from "../components/shortcodes/Presse";

const shortcodes = { Engagement, Cta, Presse };

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  return (
    <div className="bg-art-beige">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white shadow-xl">
        <Header />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="lg:w-2/3 mb-8">
          <Ariane fil_ariane={fil_ariane} />
          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-art-beige overflow-y-auto paragraph">
                <div className="relative bg-art-beige overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-4">
                    <div className="pt-8 text-lg max-w-prose mx-auto lg:pt-0">
                      <h1>
                        <span className="mb-10 block text-2xl text-left leading-8 font-bold tracking-tight text-art-marron-fonce sm:text-2xl lg:text-6xl">
                          {title}
                        </span>
                      </h1>
                      {/* <p className="mt-8 mb-8 text-xl text-art-marron-fonce leading-8">
                            {excerpt}
                          </p> */}
                      <div className="rounded-3xl shadow-lg bg-gray-900/5 lg:rounded-3xl">
                        <GatsbyImage
                          className="rounded-3xl shadow-lg"
                          alt={thumbnailText}
                          image={getImage(embeddedImagesLocal[0])}
                        />
                      </div>
                    </div>

                    <div className="prose-h2:text-xl prose-h3:text-2xl mt-6 prose-a:text-art-bleu prose-headings:text-art-marron-fonce prose prose-blue prose-lg text-art-marron-fonce mx-auto">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer
                          className="mt-6 prose prose-slate prose-lg text-gray-500 mx-auto"
                          remoteImages={embeddedImagesRemote}
                          localImages={embeddedImagesLocal}
                        >
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="self-start top-16 col-span-1 p-4 lg:py-16 lg:w-1/3">
          <Sidebar />
          <AvisGoogle />
        </aside>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;
